import 'nouislider/dist/nouislider.min.css'
import './edenland-theme/fonts/permanent/stylesheet.css'
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'ekko-lightbox/dist/ekko-lightbox.css'
import './edenland-theme/css/style.default.css'
import '../assets/css/validate-forms.scss'
import './css/base.scss'


import 'owl.carousel'
import 'owl.carousel2.thumbs'
import 'nouislider'
import 'smooth-scroll'
import 'ekko-lightbox'
import 'object-fit-images'
import './edenland-theme/js/theme'
import './edenland-theme/js/weather'

import './edenland-theme/icons/orion-svg-sprite.svg'

global.SmoothScroll = require('smooth-scroll');
global.objectFitImages = require('object-fit-images');
global.ekkoLightbox = require('ekko-lightbox');
global.noUiSlider = require('nouislider');

global.basePath = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split('/')[1];