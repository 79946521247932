/*SUBSCRIBE HERE FOR API KEY: https://home.openweathermap.org/users/sign_up*/
const apiKey = "4d8fb5b93d4af21d66a2948710284366";
let url = `https://api.openweathermap.org/data/2.5/weather?q=Balotesti&appid=${apiKey}&units=metric`;
url = '/cache-proxy?url=' + encodeURIComponent(url);

fetch(url)
    .then(response => response.json())
    .then(data => {

            const {main, name, sys, weather} = data;
            const icon = `https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/${
                weather[0]["icon"]
            }.svg`;

        const weatherTempElem = document.getElementById('weatherTemp');
        const weatherImageElem = document.getElementById('weatherImage');
        weatherTempElem.innerHTML = `${Math.round(main.temp)}&ordm;`;
        weatherImageElem.innerHTML = `<img class="img-fluid" src="${icon}" alt="${
            weather[0]["description"]
        }">`;
    });